<template>
  <Loader
    :key="$path('title', post)"
    :value="promises.fetch"
    class="post-page"
    :class="`post-page--${source}`"
  >
    <Blocks v-if="item.posts" :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/posts'
import { Blocks } from '@kvass/pagebuilder'

// custom components
import Html from '@/components/Html'
import Portal from '@/components/Portal'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      return Config[this.source].call(this)
    },
    source() {
      return (this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage'])[0]
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.slug)

      // redirect
      if (!post) {
        this.$router.push({ name: 'Page' })
      }

      return post
    },
    customComponents() {
      return { Portal, Html }
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  background-color: var(--background-color);

  .kpb-section {
    border-top: 1px solid currentColor;
  }

  .contact-section {
    @include respond-below('tablet') {
      grid-template-columns: 1fr;
    }

    .lead__form {
      --vue-elder-primary-border: white;
      background-color: inherit;
      margin-top: 1rem;

      .elder-input {
        color: white;
        &__label {
          font-weight: normal;
          color: var(--primary-contrast);
        }
        &__field {
          background-color: var(--primary);
          border-color: white;
        }
      }

      &-title {
        h2 {
          font-weight: bold;
          margin: 0;
          font-size: 2rem;
        }
        p {
          opacity: 0.7;
        }
      }

      &-actions button {
        width: 100%;
        color: var(--primary);
        background-color: white;
      }
    }
  }
}
</style>
