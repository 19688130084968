import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }
  return [
    {
      condition: get('media.cover.length') && !get('customFields.page-settings.cover-under-intro'),
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '21/9',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: TransformTag(get('content')),
            },
            props: {
              class: 'post-intro',
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        style: 'border: none; padding-top: 0',
      },
      blocks: {
        default: {
          component: 'Grid',
          condition:
            get('media.cover.length') && get('customFields.page-settings.cover-under-intro'),
          blocks: {
            default: [
              ...get('media.cover').map(item => ({
                component: 'Media',
                props: {
                  type: item.type.split('/')[0],
                  style: 'height: auto; aspect-ratio: unset !important;',
                },
                data: item,
              })),
            ],
          },
        },
      },
    },

    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
        },
      }
    }),
    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('customFields.icons-settings.theme'),
        title: 'Fordeler ved å samarbeide med oss',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme'),
              iconBorder: get('customFields.icons-settings.icon-border'),
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant'),
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: 'article__content text-hero text',
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.project-portal.content'),
      props: {
        width: 'small',
        style: 'padding-bottom: 0',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: TransformTag(get('customFields.project-portal.content')),
            },
            props: {
              class: 'post-intro',
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.project-portal'),
      props: {
        style: 'border: none',
      },
      blocks: {
        default: [
          {
            component: 'Portal',
            variant: 'project',
            props: {
              hideFilter: true,
              theme: get('customFields.project-portal.theme') || 'card',
              statusFilter: get('customFields.status-filter') || 'sale',
              maxItems: parseInt(get('customFields.project-portal.max-items')) || 50,
              layout: get('customFields.project-portal.layout'),
              excluded: get('customFields.project-portal.excluded-projects'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.contacts.length'),
      props: {
        class: 'contacts-section',
        width: 'medium',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            class: 'contact-section',
          },
          blocks: {
            default: [
              {
                component: 'Portal',
                variant: 'contact',
                data: {
                  content: (get('customFields.contacts') || [])
                    .filter(c => c.avatar)
                    .map(contact => {
                      return {
                        ...contact,
                        media: {
                          cover: [contact.avatar],
                        },
                      }
                    }),
                },
                props: {
                  theme: 'thumbnail',
                  disableNav: true,
                  hideFilter: true,
                  columns: 1,
                },
              },
              {
                component: 'Lead',
                condition: get('customFields.page-settings.display-lead'),

                data: {
                  type: 'page',
                  reference: { onModel: 'Page', ref: get('item.id') },
                  fields: ['!upsell', '!title'],
                  defaultData: {},
                  actionLabel: get('customFields.lead.action-label', 'Root.item') || 'Send',
                },
                blocks: {
                  top: [
                    {
                      component: 'Text',
                      props: {
                        class: 'lead__form-title',
                      },
                      data: {
                        content: `<h2>${get('customFields.contact-form-text.title') || ''}</h2>
                  <p>${get('customFields.contact-form-text.description') || ''}</p>`,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },
  ]
}
