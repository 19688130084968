import { TransformTag } from '@/utils'

export default function () {
	const get = (path, source = 'Root.item.posts') => {
		const slug = this.$path('_props.slug', this)
		if (!slug) return

		let data = this.$path(source, this.$store.state) || []
		if (source.includes('posts')) {
			data = data.find(p => p.slug === slug)

			if (!data) return
		}

		return this.$path(path, data)
	}

	return [
		{
			condition: get('media.cover.length'),
			component: 'Section',
			props: {
				width: 'full',
				style: 'padding:0;',
			},
			blocks: {
				default: [
					{
						component: 'Slider',
						props: {
							aspectRatio: '21/9',
							class: 'hero__slider',
						},
						data: get('media.cover'),
					},
				],
			},
		},
		{
			component: 'Section',
			props: {
				width: 'small',
			},
			blocks: {
				default: [
					{
						component: 'Text',
						data: {
							content: TransformTag(get('content')),
						},
						props: {
							class: 'post-intro',
						},
					},
				],
			},
		},
		{
			component: 'Section',
			props: {
				width: 'medium',
			},
			blocks: {
				default: [
					{
						component: 'Portal',
						variant: 'project',
						props: {
							hideFilter: false,
							theme: get('customFields.project-portal.theme') || 'card',
							statusFilter: get('customFields.status-filter') || 'all',
							maxItems: parseInt(get('customFields.project-portal.max-items')) || 50,
							layout: get('customFields.project-portal.layout'),
							excluded: get('customFields.project-portal.excluded-projects'),
						},
					},
				],
			},
		},
		...(get('customFields.additional-sections-1') || []).map(i => {
			return {
				condition: this.$path('content', i) || this.$path('images.0', i),
				component: 'ImagesTextSection',
				data: {
					content: i,
				},
				props: {
					layout: i.layout,
					width: i.width,
					theme: i.theme || 'none',
				},
			}
		}),
		{
			component: 'Section',
			condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
			props: {
				class: 'contact-cta-bottom ',
				style: 'padding-block: 3.5rem;',
			},

			blocks: {
				default: [
					{
						component: 'Flex',
						props: {
							flexDirection: 'column',
							alignItems: 'center',
							class: 'contact-cta-bottom__content',
						},

						blocks: {
							default: [
								{
									component: 'Text',
									condition: get('customFields.contact-cta.content'),
									data: {
										content: get('customFields.contact-cta.content'),
									},
									props: {
										textAlign: 'center',
									},
								},
								{
									component: 'Button',
									condition: get('customFields.contact-cta.button'),
									data: {
										label: get('customFields.contact-cta.button'),
										to: { name: 'Contact' },
										theme: 'primary',
										tag: 'router-link',
										icon: ['fas', 'arrow-right'],
									},
								},
							],
						},
					},
				],
			},
		},
	]
}
