<template>
  <div v-fi="data" class="kpb-html" v-html="data.content"></div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
      validator: val => 'content' in val,
    },
  },
}
</script>
